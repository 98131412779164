import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IApplicationState as ApplicationState } from "../../stores";
import { InjectedIntlProps, injectIntl } from "react-intl";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/fontawesome-free-solid";

export enum AlertType {
    Success,
    Info,
    Warn,
    Error
}

type AlertProps =
    InjectedIntlProps
    & {
        message: any,
        type: AlertType,
        isOverlay?: boolean,
        dismissable?: boolean,
        dismissableSmallScreensOnly?: boolean,
        classNames?: string
    };


export class Alert extends React.Component<AlertProps, { message: string, alertClass: string }> {

    constructor(prop: AlertProps) {
        super(prop);

        this.state = { message: prop.message, alertClass: this.getAlertClassFromProps(prop) };
        this.onDismiss = this.onDismiss.bind(this);
    }

    public componentWillReceiveProps(nextProps: AlertProps) {
        this.setState({ message: nextProps.message, alertClass: this.getAlertClassFromProps(nextProps) });
    }

    public onDismiss() {
        this.setState({ message: null });
    }

    public getAlertClassFromProps(props: AlertProps): string {
        let alertClass = "alert ";

        switch (props.type) {
            case AlertType.Error:
                alertClass += "alert-danger ";
                break;
            case AlertType.Info:
                alertClass += "alert-info ";
                break;
            case AlertType.Warn:
                alertClass += "alert-warning ";
                break;
            case AlertType.Success:
                alertClass += "alert-success ";
                break;
        }

        if (props.dismissable) {
            alertClass += "alert-dismissable";
        }

        return alertClass;
    }

    public getAlertComponent(classNames: string) {
        return <div className={classNames + " " + this.props.classNames + " " + this.state.alertClass}>
            <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />
            {this.state.message}
            {this.props.dismissable || this.props.dismissableSmallScreensOnly ? <button type="button" className="close" onClick={this.onDismiss}>×</button> : ''}
        </div>;
    }

    public render() {
        const overlayAlertComponent = this.getAlertComponent((this.props.isOverlay ? " alert-overlay " : "") + (this.props.dismissableSmallScreensOnly ? " alert-mobile-dismissible " : ""));
        const placeHolderComponent = this.props.isOverlay ? this.getAlertComponent(" invisible ") : null;

        return (
            <span className={(this.state.message ? "" : " hidden")}>
                {overlayAlertComponent}
                {placeHolderComponent}
            </span>
        );
    }
}

function mapStateToProps(state: ApplicationState, props: AlertProps) {
    return {
        ...state.link,
        ...props
    };
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({}, dispatch) };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Alert));