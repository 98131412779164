import * as _ from "lodash";
import { Action, Reducer } from "redux";
import { IAppThunkAction as AppThunkAction } from "./index";
import { ApiException, IExceptionModel } from "../utils/ApiException";
import { MimeTypeUtils } from "../utils/MimeTypeUtils";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IEvidenceState {
    evidences?: IPublicEvidence[];
}

interface IFileModel {
    fileId: string;
    publicEvidenceId: number;
    resourceId: string;
    uri?: string;
}
export enum PublicEvidenceState {
    Starting,
    Uploading,
    Uploaded,
    Completing,
    Completed,
    Error
}

export enum PublicEvidenceDescriptionState {
    NotUpdated,
    Updating,
    Updated,
    Error
}

export interface IPublicEvidence {
    publicEvidenceId: number;
    localFile: File;
    remoteFile?: IFileModel;
    description?: string;
    state?: PublicEvidenceState;
    descriptionState?: PublicEvidenceDescriptionState;
    uploadProgress?: number;
    uploadSpeed?: number;
    uploadSpeedAverage?: number;
    uploadRemainingDurationSeconds?: number;
    uploadError?: string;
}

export enum EvidenceErrorCodes {
    caseClosed = "CaseClosed",
    maxFileCountReached = "MaxFileCountReached"
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface ICreateEvidenceAction { type: "CREATE_EVIDENCE"; evidence: IPublicEvidence; }
interface ICreateEvidenceResponseAction { type: "CREATE_EVIDENCE_RESPONSE"; publicEvidenceId: number; isSuccess: boolean; error?: ApiException<IExceptionModel>; remoteFile?: IFileModel; }
interface IUpdateEvidenceAction { type: "UPDATE_EVIDENCE"; publicEvidenceId: number; description?: string; }
interface IUpdateEvidenceResponseAction { type: "UPDATE_EVIDENCE_RESPONSE"; publicEvidenceId: number; isSuccess: boolean; error?: ApiException<IExceptionModel>; }
interface IUploadEvidenceProgressAction { type: "UPLOAD_EVIDENCE_PROGRESS"; publicEvidenceId: number; progress: number; speed: number; }
interface IUploadEvidenceCompleteAction { type: "UPLOAD_EVIDENCE_COMPLETE"; publicEvidenceId: number; isSuccess: boolean; error?: string; }
interface ICompleteEvidenceAction { type: "COMPLETE_EVIDENCE"; publicEvidenceId: number; }
interface ICompleteEvidenceResponseAction { type: "COMPLETE_EVIDENCE_RESPONSE"; publicEvidenceId: number; isSuccess: boolean; error?: ApiException<IExceptionModel>; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ICreateEvidenceAction
    | IUpdateEvidenceAction
    | IUpdateEvidenceResponseAction
    | ICreateEvidenceResponseAction
    | IUploadEvidenceProgressAction
    | IUploadEvidenceCompleteAction
    | ICompleteEvidenceAction
    | ICompleteEvidenceResponseAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IActionCreators {
    createEvidences?: (files: File[], recaptchaResponse: string) => AppThunkAction<KnownAction>;
    updateEvidence?: (publicEvenceId: number, description?: string) => AppThunkAction<KnownAction>;
    uploadProgressEvidence?: (publicEvidenceId: number, progress: number, speed: number) => AppThunkAction<KnownAction>;
    uploadCompleteEvidence?: (publicEvidenceId: number, isSuccess: boolean, error?: string, checksum?: string) => AppThunkAction<KnownAction>;
    completeEvidence?: (publicEvidenceId: number, checksum?: string) => AppThunkAction<KnownAction>;
}

export const actionCreators: IActionCreators = {
    createEvidences: (files: File[], recaptchaResponse: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const evidences: IPublicEvidence[] = [];
        const localFiles = [];

        files.forEach(file => {
            const publicEvidenceId = Math.floor(Math.random()*(Number.MAX_SAFE_INTEGER));
            evidences.push({
                publicEvidenceId,
                localFile: file,
                uploadSpeed: 0,
                uploadProgress: 0,
                uploadRemainingDurationSeconds: 0
            });

            localFiles.push({
                mimeType: MimeTypeUtils.getType(file.name, file.type),
                name: file.name,
                fileSize: file.size,
                publicEvidenceId
            });

            dispatch({ type: "CREATE_EVIDENCE", evidence: evidences[evidences.length - 1]});
        });

        const response = await fetch(`/api/v1/link/${getState().link.linkId}/file`, {
            method: "POST",
            body: JSON.stringify({
                localFiles,
                userInfo: getState().link.linkUserInfo,
                recaptchaResponse
            }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (!response.ok) {
            const responseBody = await response.json();
            evidences.forEach(async evidence => {
                dispatch({ type: "CREATE_EVIDENCE_RESPONSE", publicEvidenceId: evidence.publicEvidenceId, isSuccess: false, error: await ApiException.parse<IExceptionModel>(responseBody) });
            });
        } else {
            const remoteFiles: IFileModel[] = (await response.json()) as IFileModel[];
            remoteFiles.forEach(async (remoteFile, index) => {
                dispatch({ type: "CREATE_EVIDENCE_RESPONSE", publicEvidenceId: remoteFile.publicEvidenceId, isSuccess: true, remoteFile });
            });
        }
    },
    updateEvidence: (publicEvidenceId: number, description?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_EVIDENCE", publicEvidenceId, description});
        const currentEvidence = getState().evidence.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === publicEvidenceId);
        const response = await fetch(`/api/v1/link/${getState().link.linkId}/file/${currentEvidence.remoteFile.fileId}`, {
            method: "PUT",
            body: JSON.stringify({
                description,
                userInfo: getState().link.linkUserInfo
            }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (!response.ok) {
            dispatch({ type: "UPDATE_EVIDENCE_RESPONSE", publicEvidenceId, isSuccess: false, error: await ApiException.parse<IExceptionModel>(response) });
        } else {
            dispatch({ type: "UPDATE_EVIDENCE_RESPONSE", publicEvidenceId, isSuccess: true });
        }
    },
    uploadProgressEvidence: (publicEvidenceId: number, progress: number, speed: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPLOAD_EVIDENCE_PROGRESS", publicEvidenceId, progress, speed});
    },
    uploadCompleteEvidence: (publicEvidenceId: number, isSuccess: boolean, error?: string, checksum?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPLOAD_EVIDENCE_COMPLETE", publicEvidenceId, isSuccess, error});
        if (isSuccess) {
            dispatch(actionCreators.completeEvidence(publicEvidenceId, checksum) as any);
        }
    },
    completeEvidence: (publicEvidenceId: number, checksum?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "COMPLETE_EVIDENCE", publicEvidenceId});
        const currentEvidence = getState().evidence.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === publicEvidenceId);
        const resourceId = currentEvidence.remoteFile.resourceId;
        const response = await fetch(`/api/v1/link/${getState().link.linkId}/file/${currentEvidence.remoteFile.fileId}/complete`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userInfo: getState().link.linkUserInfo,
                CompleteEvidenceResources: [
                    {
                        ResourceId: resourceId,
                        Checksum: checksum
                    }
                ],
                ChecksumAlgorithm: "Sha512"
            }),
        });
        if (!response.ok) {
            dispatch({ type: "COMPLETE_EVIDENCE_RESPONSE", publicEvidenceId, isSuccess: false, error: await ApiException.parse<IExceptionModel>(response) });
        } else {
            dispatch({ type: "COMPLETE_EVIDENCE_RESPONSE", publicEvidenceId, isSuccess: true });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IEvidenceState> = (state: IEvidenceState, action: KnownAction) => {
    switch (action.type) {
        case "CREATE_EVIDENCE":
            action.evidence.state = PublicEvidenceState.Starting;
            action.evidence.descriptionState = PublicEvidenceDescriptionState.NotUpdated;
            return { ...state, evidences: [ ...state.evidences, action.evidence] };
        case "CREATE_EVIDENCE_RESPONSE":
            const currentEvidence = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            if (action.isSuccess) {
                currentEvidence.state = PublicEvidenceState.Uploading;
                currentEvidence.remoteFile = action.remoteFile;
            } else {
                currentEvidence.state = PublicEvidenceState.Error;
                currentEvidence.uploadError = action.error.errorCode;
            }
            return { ...state, evidences: [...state.evidences] };
        case "UPDATE_EVIDENCE":
            const currentUpdateEvidence = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            currentUpdateEvidence.description = action.description;
            currentUpdateEvidence.descriptionState = PublicEvidenceDescriptionState.Updating;
            return { ...state, evidences: [...state.evidences] };
        case "UPDATE_EVIDENCE_RESPONSE":
            const currentUpdateEvidenceResponse = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            currentUpdateEvidenceResponse.descriptionState = action.isSuccess ? PublicEvidenceDescriptionState.Updated : PublicEvidenceDescriptionState.Error;
            return { ...state, evidences: [...state.evidences] };
        case "UPLOAD_EVIDENCE_PROGRESS":
            const currentUploadProgressEvidence = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            currentUploadProgressEvidence.uploadProgress = action.progress;
            currentUploadProgressEvidence.uploadSpeed = action.speed;
            const SMOOTHING_FACTOR = 0.5;
            if (currentUploadProgressEvidence.uploadSpeedAverage === 0 || currentUploadProgressEvidence.uploadSpeedAverage == null) {
                currentUploadProgressEvidence.uploadSpeedAverage = action.speed;
            } else {
                // To calculate the average, we use an exponential moving average.
                currentUploadProgressEvidence.uploadSpeedAverage = SMOOTHING_FACTOR * action.speed + (1-SMOOTHING_FACTOR) * currentUploadProgressEvidence.uploadSpeedAverage;
                // To calculate the remaining time, we take the remainging size and divide by average speed.
                const remainingSizeByte = currentUploadProgressEvidence.localFile.size - (currentUploadProgressEvidence.localFile.size * currentUploadProgressEvidence.uploadProgress);
                const uploadSpeedBytePerSeconds = (currentUploadProgressEvidence.uploadSpeedAverage / 8) * 1000000;
                currentUploadProgressEvidence.uploadRemainingDurationSeconds = remainingSizeByte / uploadSpeedBytePerSeconds;
            }
            return { ...state, evidences: [...state.evidences] };
        case "UPLOAD_EVIDENCE_COMPLETE":
            const currentCompleteUploadFile = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            if (action.isSuccess) {
                currentCompleteUploadFile.state = PublicEvidenceState.Uploaded;
            } else {
                currentCompleteUploadFile.state = PublicEvidenceState.Error;
                currentCompleteUploadFile.uploadError = action.error;
            }
            return { ...state, evidences: [...state.evidences] };
        case "COMPLETE_EVIDENCE":
            const currentCompletingEvidence = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            currentCompletingEvidence.state = PublicEvidenceState.Completing;
            return { ...state, evidences: [...state.evidences] };
        case "COMPLETE_EVIDENCE_RESPONSE":
            const currentCompletedEvidence = state.evidences.find((evidence: IPublicEvidence) => evidence.publicEvidenceId === action.publicEvidenceId)
            if (action.isSuccess) {
                currentCompletedEvidence.state = PublicEvidenceState.Completed;
            } else {
                currentCompletedEvidence.state = PublicEvidenceState.Error;
                currentCompletedEvidence.uploadError = action.error.errorCode
            }
            return { ...state, evidences: [...state.evidences] };
        default:
        {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
        }

    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || getInitialState();
};

function getInitialState(): IEvidenceState {
    return {
        evidences: []
    };
}
