import { History } from "history";
import { intlReducer } from "react-intl-redux";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { applyMiddleware, combineReducers, compose, createStore, GenericStoreEnhancer, ReducersMapObject, Store, StoreEnhancerStoreCreator } from "redux";
import thunk from "redux-thunk";
import * as Link from "./link";
import * as Evidence from "./evidence";
import * as Feature from "./feature";
import { reducer as formReducer } from "redux-form";

export interface IClientSettingsState {
    googleApiKey?: string;
    recaptchaApiKey?: string;
    themeServiceUrl?: string;
}

export interface IIntl {
    locale?: string;
    messages?: any;
    isLtr?: boolean;
}

// The top-level state object
export interface IApplicationState {
    intl: IIntl;
    clientSettings: IClientSettingsState;
    feature: Feature.IFeatureState;
    link: Link.ILinkState;
    evidence: Evidence.IEvidenceState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    link: Link.reducer,
    evidence: Evidence.reducer,
    feature: Feature.reducer,
    form: formReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface IAppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => IApplicationState): void;
}

export default function configureStore(history: History, initialState: any) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === "undefined" ? null : window as any;
    // If devTools is installed, connect to it
    const devToolsExtension = windowIfDefined && windowIfDefined.devToolsExtension as () => GenericStoreEnhancer;
    const createStoreWithMiddleware: any = compose(
        applyMiddleware(thunk, routerMiddleware(history)/*, logger*/),
        devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next
    )(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer(reducers);
    const store = createStoreWithMiddleware(allReducers, initialState) as Store<IApplicationState>;
    // Enable Webpack hot module replacement for reducers
    if ((module as any).hot) {
        (module as any).hot.accept("./", () => {
            const nextRootReducer = require("./") as any;
            store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
        });
    }

    return store;
}

function buildRootReducer(allReducers: ReducersMapObject) {
    return combineReducers<IApplicationState>({ ...allReducers, routing: routerReducer, intl: intlReducer, clientSettings: (state = {}) => state });
}
