import * as _ from "lodash";
import { Action, Reducer } from "redux";
import { IAppThunkAction as AppThunkAction } from "./index";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IFeatureState {
    faceDetectionFeature?: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface ISetFaceDetectionFeatureAction { type: "SET_FEATURE_FACEDETECTION"; status: boolean; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = { type: ""} | ISetFaceDetectionFeatureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IActionCreators {
    setFaceDetectionFeature?: (status: boolean) => AppThunkAction<KnownAction>;
}

export const actionCreators: IActionCreators = {
    setFaceDetectionFeature: (status: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "SET_FEATURE_FACEDETECTION", status});
    }
};
function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IFeatureState> = (state: IFeatureState, action: KnownAction) => {
    switch (action.type) {
        case "SET_FEATURE_FACEDETECTION":
        {
            localStorage.setItem("faceDetectionFeature", action.status.toString());
            return { ...state, faceDetectionFeature: action.status };
        }
        case "":
            return { ...state };
        default:
        {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
        }

    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || getInitialState();
};

function getInitialState(): IFeatureState {

    return {
        faceDetectionFeature: false
    };
}
