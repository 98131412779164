import * as _ from "lodash";
import { Action, Reducer } from "redux";
import { IAppThunkAction as AppThunkAction } from "./index";
import { ApiException, IExceptionModel } from "../utils/ApiException";
import { ThemeInfoModel } from "src/components/ThemeInfoModel";

export function hasTermsAndConditions(termsAndConditions: string): boolean {
    return (termsAndConditions || "").trim().length > 0;
};

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface ILinkState {
    isLinkLoading?: boolean;
    isLinkLoaded?: boolean;
    isLinkError?: boolean;
    linkError?: ApiException<IExceptionModel>;
    linkId?: string;
    linkTitle?: string;
    linkDescription?: string;
    incidentFromUtc?: Date;
    incidentToUtc?: Date;
    linkExpirationUtc?: Date;
    location?: { latitude: number, longitude: number }
    locationAddress?: string;
    linkUserInfo?: ILinkUserInfo;
    isTermAccepted?: boolean;
    uploadPrivacy?: UploadPrivacyType;
    tenantName?: string;
    tenantId?: string;
    tenantTheme?: ThemeInfoModel;
    termsAndConditions?: string;
    contactInfo?: {
        emailAddress?: string;
        phoneNumber?: string;
        websiteUrl?: string;
    }
}

export interface ILinkUserInfo {
    emailAddress?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
}

export enum UploadPrivacyType {
    ContactInfoRequired = "ContactInfoRequired",
    Anonymous = "Anonymous"
}

interface IRestrictedLinkModel {
    linkId: string;
    linkTitle: string;
    linkDescription?: string;
    incidentFromUtc?: string;
    incidentToUtc?: string;
    linkExpirationUtc?: string;
    location?: { latitude: number, longitude: number }
    locationAddress?: string;
    tenantName?: string;
    tenantId?: string;
    termsAndConditions?: string;
    uploadPrivacy?: UploadPrivacyType;
    contactInfo?: {
        emailAddress?: string;
        phoneNumber?: string;
        websiteUrl?: string;
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
interface ILoadLinkAction { type: "LOAD_LINK"; linkId: string; }
interface ILoadLinkResponseAction { type: "LOAD_LINK_RESPONSE"; isSuccess: boolean; link?: IRestrictedLinkModel; error?: ApiException<IExceptionModel>; }
interface IAcceptTermAction { type: "ACCEPT_TERM_LINK"; isTermAccepted: boolean; }
interface IUpdateUserInfoAction { type: "UPDATE_USER_INFO_LINK"; linkUserInfo: ILinkUserInfo; }
interface IUserGetThemeAction { type: "GET_USER_THEME"; }
interface IUserGetThemeCompletedAction { type: "GET_USER_THEME_COMPLETED"; isSuccess: boolean; themeInfo?: ThemeInfoModel; }



// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ILoadLinkAction | ILoadLinkResponseAction | IAcceptTermAction | IUpdateUserInfoAction | IUserGetThemeAction | IUserGetThemeCompletedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IActionCreators {
    loadLink?: (linkId: string) => AppThunkAction<KnownAction>;
    acceptTerm?: (isTermAccepted: boolean) => AppThunkAction<KnownAction>;
    updateUserInfo?: (userInfo: ILinkUserInfo) => AppThunkAction<KnownAction>;
    getUserTheme?: (tenantId: string) => AppThunkAction<KnownAction>;
}

export const actionCreators: IActionCreators = {
    loadLink: (linkId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "LOAD_LINK", linkId });
        const response = await fetch(`/api/v1/link/${linkId}`);
        if (!response.ok) {
            dispatch({ type: "LOAD_LINK_RESPONSE", isSuccess: false, error: await ApiException.parse<IExceptionModel>(response) });
        } else {
            const data: IRestrictedLinkModel = (await response.json()) as IRestrictedLinkModel;
            dispatch({ type: "LOAD_LINK_RESPONSE", isSuccess: true, link: data });
        }
    },
    acceptTerm: (isTermAccepted: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "ACCEPT_TERM_LINK", isTermAccepted });
    },
    updateUserInfo: (linkUserInfo: ILinkUserInfo): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "UPDATE_USER_INFO_LINK", linkUserInfo });
    },
    getUserTheme: (tenantId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "GET_USER_THEME" });
        const response = await fetch(`${getState().clientSettings.themeServiceUrl}api/v1/themes/${tenantId}/info/none`);
        if (response.ok) {
            const themeInfo: ThemeInfoModel = await response.json();
            dispatch({ type: "GET_USER_THEME_COMPLETED", isSuccess: true, themeInfo });
        } else {
            dispatch({ type: "GET_USER_THEME_COMPLETED", isSuccess: false });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ILinkState> = (state: ILinkState, action: KnownAction) => {
    switch (action.type) {
        case "ACCEPT_TERM_LINK":
            return { ...state, isTermAccepted: action.isTermAccepted };
        case "LOAD_LINK":
            return { ...state, isLinkLoading: true, isLinkLoaded: false, isLinkError: false, linkId: action.linkId };
        case "UPDATE_USER_INFO_LINK":
            return { ...state, linkUserInfo: action.linkUserInfo };
        case "LOAD_LINK_RESPONSE":
            const termsAndConditions = action.isSuccess ? action.link.termsAndConditions : null;
            return {
                ...state,
                isLinkLoading: false,
                isLinkLoaded: action.isSuccess,
                isLinkError: !action.isSuccess,
                linkError: action.error,
                linkId: action.isSuccess ? action.link.linkId : null,
                linkTitle: action.isSuccess ? action.link.linkTitle : null,
                linkDescription: action.isSuccess ? action.link.linkDescription : null,
                linkExpirationUtc: action.isSuccess && action.link.linkExpirationUtc ? new Date(action.link.linkExpirationUtc) : null,
                incidentFromUtc: action.isSuccess && action.link.incidentFromUtc ? new Date(action.link.incidentFromUtc) : null,
                incidentToUtc: action.isSuccess && action.link.incidentToUtc ? new Date(action.link.incidentToUtc) : null,
                location: action.isSuccess ? action.link.location : null,
                locationAddress: action.isSuccess ? action.link.locationAddress : null,
                uploadPrivacy: action.isSuccess ? action.link.uploadPrivacy : null,
                tenantName: action.isSuccess ? action.link.tenantName : null,
                tenantId: action.isSuccess ? action.link.tenantId : null,
                termsAndConditions,
                contactInfo: action.isSuccess ? action.link.contactInfo : null,
                isTermAccepted: !hasTermsAndConditions(termsAndConditions)
            };
        case "GET_USER_THEME":
            return { ...state };
        case "GET_USER_THEME_COMPLETED":
            if (action.isSuccess) {
                return { ...state, tenantTheme: action.themeInfo };
            } else {
                return { ...state };
            }
        default:
            {
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
            }

    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || getInitialState();
};

function getInitialState(): ILinkState {
    return {
        isLinkLoading: false,
        isLinkLoaded: false,
        isLinkError: false,
        linkError: null,
        linkId: null,
        linkTitle: null,
        linkDescription: null,
        isTermAccepted: false
    };
}
