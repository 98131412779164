import * as React from "react";
import * as GoogleAnalytics from "react-ga";
import { RouteProps } from "react-router";

initializeGA();

function initializeGA() {
    fetch("/clientSettings.json").then((response) => {
        response.json().then((clientSettings) => {
            GoogleAnalytics.initialize(clientSettings.googleAnalyticsID);
        });
    });
}

const GATracker = (Tracker, options = {}) => {
    const trackPage = (page) => {
        GoogleAnalytics.set({
            page,
            ...options,
        });
        GoogleAnalytics.pageview(page);
    };

    const GATrackerComponent = class extends React.Component<RouteProps, any> {
        public componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page);
        }

        public componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        public render() {
            return <Tracker {...this.props} />;
        }
    };

    return GATrackerComponent;
};

export default GATracker;
