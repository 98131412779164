export class BrowserUtils {

    public static isIE() {
        return navigator.userAgent.toLowerCase().match(/msie|trident/i);
    }

    public static isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);
    }

    public static isChrome() {
        return (navigator.vendor.toLowerCase().indexOf("google inc") !== -1 && navigator.userAgent.toLowerCase().indexOf("chrome") !== -1);
    }
}