import * as React from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/fontawesome-free-solid";
export default class NotFoundView extends React.Component<any, any> {
    public render() {
        return (
            <div className="flex-container pt-5">
                <h4 className="text-white p-1 pt-5 pb-5 text-center">
                    <FormattedMessage id="STE_MESSAGE_ERROR_LOADINGFILEREQUEST" />
                </h4>
                <h1 className="p-5 text-center"><FontAwesomeIcon icon={faLink} size="5x" /></h1>
                <h5 className="text-white p-1 pt-2 text-center">
                <FormattedMessage id="STE_MESSAGE_ERROR_LINKNOTFOUND" />
                </h5>
            </div>
        );
    }
}
