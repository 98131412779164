export class MimeTypeUtils {
    public static getType(fileName: string, submittedFileType: string): string {
        const extension = MimeTypeUtils.getFileExtension(fileName);
        const fileType = this.knownExtensions[extension.toLowerCase()];
        if (fileType) {
            return fileType.toLowerCase();
        }
        return submittedFileType && submittedFileType.toLowerCase() || MimeTypeUtils.defaultMimeType;
    }
    
    private static defaultMimeType = "application/octet-stream";

    private static knownExtensions = {
        "g64": "video/g64",
        "g64x": "video/g64x",
        "zip": "application/zip",
        "flv": "video/x-flv",
        "mkv": "video/x-matroska",
        "asf": "video/x-ms-asf",
        "wmv": "video/x-ms-wmv",
        "m4v": "video/x-m4v",
        "3gp2": "video/3gpp2",
        "3gpp": "video/3gpp",
        "3gp": "video/3gpp",
        "mov": "video/quicktime",
        "gxf": "application/gxf",
        "mxf": "application/mxf",
        "ts": "video/mp2t",
        "ps": "video/mp2p"
    }

    private static getFileExtension(fileName: string) {
        return (fileName || "").toLowerCase().split(".").pop();
    }
}