import * as React from "react";
import { Route, RouteProps, Switch } from "react-router";
import LayoutView from "./components/views/LayoutView";
import NotFoundView from "./components/views/NotFoundView";
import PublicView from "./components/views/PublicView";
import UploadView from "./components/views/UploadView";
import GATracker from "./GATracker";

export const routes = (
    <LayoutView>
        <Switch>
            <Route exact={true} path="/:linkId" component={GATracker(PublicView)} />
            <Route exact={true} path="/:linkId/upload" component={GATracker(UploadView)}/>
            <Route component={GATracker(NotFoundView)} />
        </Switch>
    </LayoutView>
);
