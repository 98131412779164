export enum HttpStatus {
    Undefined = 0,

    Ok = 200,

    BadRequest = 400,

    Unauthorized = 401,

    Forbidden = 403,

    NotFound = 404,

    InternalError = 500
}

export interface IExceptionModel {
    type: string;
    code: string;
    data: any;
    message: string;
}

export class ApiException<T> {
    private static get CodeProperty(): string {
        return "code";
    }

    public static async parse<T>(error: any): Promise<ApiException<T>> {
        const jsonBody = error.status === HttpStatus.BadRequest ? await error.json() : null;
        return new ApiException<T>(error.status, error.statusText, jsonBody);
    }

    constructor(private _statusCode: number, private _statusText: string, private _data: T) {
    }

    public get data() {
        return this._data;
    }

    public get statusCode() {
        return this._statusCode;
    }

    public get statusText() {
        return this._statusText;
    }

    public toString(): string {
        return `ApiException: ${this.statusCode} (${this.statusText}) - ${JSON.stringify(this.data)}`;
    }

    public get isUndefined() {
        return this._statusCode === HttpStatus.Undefined;
    }

    public get isForbidden() {
        return this._statusCode === HttpStatus.Forbidden;
    }

    public get isBadRequest() {
        return this._statusCode === HttpStatus.BadRequest;
    }

    public get isOk() {
        return this._statusCode === HttpStatus.Ok;
    }

    public get isInternalError() {
        return this._statusCode === HttpStatus.InternalError;
    }

    public get isNotFound() {
        return this._statusCode === HttpStatus.NotFound;
    }

    public isErrorType(errorCode: string): boolean {
        return this.errorCode === errorCode;
    }

    public get errorCode() {
        return this.data && this.data.hasOwnProperty(ApiException.CodeProperty)
            ? this.data[ApiException.CodeProperty]
            : null;
    }
}
