import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as LinkStore from "../../stores/link";
import * as RootStore from "../../stores/index";
import { bindActionCreators } from "redux";
import { IApplicationState as ApplicationState, IIntl } from "../../stores";
import { connect } from "react-redux";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobe, faPhone } from "@fortawesome/fontawesome-free-solid";
import { RouteComponentProps, withRouter } from "react-router-dom";

type LayoutViewProps =
    LinkStore.ILinkState
    & RootStore.IClientSettingsState
    & IIntl
    & RouteComponentProps<{ linkId: string }>
    & typeof LinkStore.actionCreators;

export class LayoutView extends React.Component<LayoutViewProps, {}> {

    public render() {
        return (
            <div className="flex-container h-75">
                <nav className="navbar navbar-expand-lg navbar-dark border-bottom px-4" dir="ltr">
                    <img className="me-auto mt-1 mb-2" src={this.props.tenantId ? `${this.props.themeServiceUrl}web/v1/tenant/${this.props.tenantId}/resource/publiclinklogo` : null} style={{ maxHeight: "72px" }} />
                    {this.props.contactInfo ?
                        <div className="d-flex flex-row" style={{ minWidth: "425px"/*IE11 Fix*/ }} dir={this.props.isLtr ? "ltr" : "rtl"}>
                            <div className="d-flex flex-column col-6 px-4">
                                <span className="text-white fw-bold"><FormattedMessage id="STE_LABEL_CONTACT" /></span>
                                <small className="text-secondary"><FormattedMessage id="STE_LABEL_CONTACTDETAILS" /></small>
                            </div>
                            <div className="d-flex flex-column col-6 px-4">
                                {this.props.contactInfo.phoneNumber != null ? <span className="text-white"><small><FontAwesomeIcon icon={faPhone} /></small><small className="ps-1 pe-1" dir="ltr">{this.props.contactInfo.phoneNumber}</small></span> : ""}
                                {this.props.contactInfo.emailAddress != null ? <span className="text-white"><small><FontAwesomeIcon icon={faEnvelope} /></small><small className="ps-1 pe-1">{this.props.contactInfo.emailAddress}</small></span> : ""}
                                {this.props.contactInfo.websiteUrl != null ? <span className="text-white"><small><FontAwesomeIcon icon={faGlobe} /></small><small className="ps-1 pe-1"><a target="_blank" href={this.props.contactInfo.websiteUrl}>{this.props.contactInfo.websiteUrl}</a></small></span> : ""}
                            </div>
                        </div>
                        : ""}
                </nav>
                <div className="flex-container h-100">
                    {this.props.children}
                </div>
            </div>);
    }
}
function mapStateToProps(state: ApplicationState, props: LayoutViewProps) {
    return {
        ...state.intl,
        ...state.link,
        ...state.clientSettings,
        ...props
    };
}
function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...LinkStore.actionCreators }, dispatch) };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutView));