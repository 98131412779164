import * as LinkStore from "../stores/link";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IApplicationState as ApplicationState, IClientSettingsState } from "../stores";
import { FormattedMessage, InjectedIntlProps, injectIntl, IntlProvider } from "react-intl";
import * as EvidenceStore from "../stores/evidence";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import {  faClock, faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { Coords, default as GoogleMapReact} from "google-map-react";
import QRCode from "qrcode.react";

type IncidentInfoProps =
    LinkStore.ILinkState
    & IClientSettingsState
    & EvidenceStore.IEvidenceState
    & typeof LinkStore.actionCreators
    & typeof EvidenceStore.actionCreators
    & InjectedIntlProps
    & {CollapseMap: boolean, CollapseQR: boolean};

export class IncidentInfo extends React.Component<IncidentInfoProps, {CollapseMap: boolean, CollapseQR: boolean}> {

    
    public constructor(props) {
        super(props);
        this.state = {CollapseMap: props.CollapseMap, CollapseQR: props.CollapseQR}
    }

    public AnyReactComponent = ({ text, lat, lng }) => <div style={{position: "relative", top: "-15px", left: "-10px"}}>{text}</div>;

    public handleToggleMap = () => {
        this.setState({CollapseMap: !this.state.CollapseMap, CollapseQR: this.state.CollapseQR});
    }
    public handleToggleQR = () => {
        this.setState({CollapseMap: this.state.CollapseMap, CollapseQR: !this.state.CollapseQR});
    }

    public render() {
        return (
            <div className="d-flex justify-content-center w-100 text-white">
                <div className="box ps-1 pe-1 bg-dark col-12 col-sm-10 col-md-9 col-lg-7 col-xl-7 d-flex flex-column justify-content-start">
                    <div>
                        <h4 className="ps-3 pt-3 pb-0 mb-0">{this.props.linkTitle}</h4>
                        <div className="ps-1">
                            <button className="btn btn-link" onClick={this.handleToggleQR}>
                                {this.state.CollapseQR ? <FormattedMessage id="STE_BUTTON_SHOWQRCODE" /> : <FormattedMessage id="STE_BUTTON_HIDEQRCODE" />}
                            </button>
                        </div>
                        { this.state.CollapseQR === false ?
                            <div className="col-12 ps-3">
                                <QRCode value={window.location.href} renderAs="svg" className="border border-white" />
                            </div>
                        : ""}
                    </div>
                    <div className="row d-flex p-3">
                        { this.props.location != null && this.props.location.latitude != null && this.props.location.longitude != null ?
                        <div className="col-12 col-md-6 col-lg-6 d-flex flex-column pb-3">
                            <span className="text-secondary"><FontAwesomeIcon icon={faMapMarkerAlt} className="pe-1 ps-1" size="2x" /><FormattedMessage id="STE_LABEL_LOCATION"/></span>
                                <small className="text-white pe-2">{this.props.locationAddress}</small>
                        </div>
                        : ""}
                        { this.props.incidentFromUtc != null || this.props.incidentToUtc != null ?
                        <div className="col-12 col-md-6 col-lg-6 d-flex flex-column pb-3">
                            <span className="text-secondary"><FontAwesomeIcon icon={faClock} className="pe-1 ps-1" size="2x" /><FormattedMessage id="STE_LABEL_INCIDENTTIME"/></span>
                            <small className="text-white">{this.getTimeRangeDateTimeLabel(this.props.incidentFromUtc, this.props.incidentToUtc)}</small>
                        </div>
                        : ""}
                        {  this.props.location != null && this.props.location.latitude != null && this.props.location.longitude != null ?
                        
                        <div className="col-md-12 col-lg-6 d-flex ps-0">
                                <button className="btn btn-link" onClick={this.handleToggleMap}>
                                    {this.state.CollapseMap ? <FormattedMessage id="STE_BUTTON_SHOWMAP" /> : <FormattedMessage id="STE_BUTTON_HIDEMAP" />}
                                </button>
                            </div>
                        : ""}
                    </div>
                    {  this.props.location != null && this.props.location.latitude != null && this.props.location.longitude != null && !this.state.CollapseMap ?
                    <div style={{ height: '300px', width: '100%' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: this.props.googleApiKey }}
                        defaultCenter={{
                            lat: this.props.location != null ? this.props.location.latitude : 0,
                            lng: this.props.location != null ? this.props.location.longitude : 0
                        } as Coords }
                        defaultZoom={14}
                        >
                        <this.AnyReactComponent
                            lat={ this.props.location != null ? this.props.location.latitude : 0}
                            lng={ this.props.location != null ? this.props.location.longitude : 0}
                            text={<FontAwesomeIcon icon={faMapMarkerAlt} className="text-danger" size="2x" />}
                        />
                        </GoogleMapReact>
                    </div>
                    : ""}
                    <div className="p-3 fw-light pb-5">
                        {this.props.linkDescription}
                    </div>
                </div>
            </div>
        );
    }

    private getTimeRangeDateTimeLabel (from: Date, to: Date): string {
        let timeRangeDateTimeLabel = null;

        from = from ? from : to;
        to = to ? to : from;

        const isSameDay = to.getDate() === from.getDate() && to.getMonth() === from.getMonth() && to.getFullYear() === from.getFullYear();
        const isSameHour = from.getHours() === to.getHours() && from.getMinutes() === to.getMinutes();

        if (isSameDay) {
            if (isSameHour) {
                timeRangeDateTimeLabel = this.props.intl
                    .formatDate(from, { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" });
            } else {
                timeRangeDateTimeLabel = this.props.intl
                    .formatDate(from, { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }) + ` ${this.props.intl.formatMessage({ id: "ToLabel"})} ` + this.props.intl
                        .formatDate(to, { hour: "numeric", minute: "numeric" });
            }
        } else {
            timeRangeDateTimeLabel = this.props.intl
                .formatDate(from, { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }) + ` ${this.props.intl.formatMessage({ id: "ToLabel"})} ` + this.props.intl
                    .formatDate(to, { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" });
        }
        
        return timeRangeDateTimeLabel;
    }
}

function mapStateToProps(state: ApplicationState, props: IncidentInfoProps) {
    return {
        ...state.link,
        ...state.evidence,
        ...state.clientSettings,
        ...props
    };
}
function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...LinkStore.actionCreators, ...EvidenceStore.actionCreators}, dispatch) };
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IncidentInfo));
