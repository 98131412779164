import * as React from "react";
import Recaptcha from "react-google-recaptcha";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { IApplicationState as ApplicationState, IClientSettingsState } from "../../stores";
import * as LinkStore from "../../stores/link";
import * as EvidenceStore from "../../stores/evidence";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faFile, faFilm, faImage, faMicrophone } from "@fortawesome/fontawesome-free-solid";
import Dropzone from "react-dropzone";
import EvidenceUpload from "../EvidenceUpload";
import IncidentInfo from "../IncidentInfo";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Alert, { AlertType } from "../generic/Alert";
import { BrowserUtils } from "../../utils/BrowserUtils";

type UploadViewProps =
    LinkStore.ILinkState
    & IClientSettingsState
    & EvidenceStore.IEvidenceState
    & typeof LinkStore.actionCreators
    & typeof EvidenceStore.actionCreators
    & { push }
    & InjectedIntlProps
    & RouteComponentProps<{ linkId: string }>;


class UploadView extends React.Component<UploadViewProps> {

    private droppedFiles: File[] = null;
    private recaptchaRef: React.RefObject<Recaptcha> = React.createRef<Recaptcha>()

    constructor(props) {
        super(props);

        if (props.linkId !== props.match.params.linkId || props.isTermAccepted !== true) {
            this.props.push(`/${props.match.params.linkId}`);
        }
    }

    public render() {

        if (this.props.isLinkLoaded === false && this.props.isLinkError === false) {
            return this.renderLinkInitial();
        } else if (this.props.isLinkLoading === true) {
            return this.renderLinkLoading();
        } else if (this.props.isLinkLoading === false && this.props.isLinkLoaded === false && this.props.isLinkError === true) {
            return this.renderLinkError();
        } else {
            return this.renderLink();
        }
    }

    private renderSlowUploadWarningIfIE = () => {

        if (BrowserUtils.isIE()) {
            return (
                <Alert
                    dismissable={true}
                    dismissableSmallScreensOnly={false}
                    isOverlay={false}
                    type={AlertType.Warn}
                    classNames="no-margin-bottom"
                    message={this.props.intl.formatMessage({ id: "STE_MESSAGE_WARNING_UPLOADINTERNETEXPLORER", defaultMessage: "Large files might upload slowly, due to Internet Explorer encryption performance. Consider using Chrome or Edge for faster results." })} />
            );
        }

    }

    private renderLink() {
        const hasEvidence: boolean = this.props.evidences.length > 0;
        return (
            <div className="flex-container h-100 text-white">

                <div className="">
                    <h4 className="p-3 text-center"><FormattedMessage id="STE_TITLE_UPLOADPAGE" /></h4>
                </div>
                <IncidentInfo CollapseMap={true} CollapseQR={true} />
                <div className="d-flex justify-content-center w-100 pt-4">
                    <div className={`col-12 d-flex row`}>
                        <TransitionGroup className={`col-12 col-md-7 ${hasEvidence === true ? "" : 'd-none'}`}>
                            {this.props.evidences.map((evidence: EvidenceStore.IPublicEvidence) =>
                                <CSSTransition key={evidence.publicEvidenceId} timeout={500} classNames="fade">
                                    <EvidenceUpload key={evidence.publicEvidenceId.toString()} publicEvidenceId={evidence.publicEvidenceId} />
                                </CSSTransition>)}
                        </TransitionGroup>
                        <div className={`${hasEvidence === true ? "col-12 col-md-5 " : "col-12"} pb-5`}>
                            {this.renderSlowUploadWarningIfIE()}
                            <div className="col-12 border border-light border-dashed rounded box bg-dark d-flex justify-content-center w-100">
                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) =>
                                    <div className="bg-dark w-75 d-flex flex-column justify-content-center" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div>
                                            <h4 className="p-3 text-center"><FormattedMessage id="STE_LABEL_DROPZONE_INFO" /></h4>
                                        </div>
                                        <div>
                                            <h4 className="p-3 text-center text-uppercase"><FormattedMessage id="STE_LABEL_DROPZONE_OR" /></h4>
                                        </div>
                                        <div className="p-3 text-center">
                                            <button className="btn btn-primary"><FormattedMessage id="STE_BUTTON_DROPZONE_SELECT_FILE" /></button>
                                        </div>
                                        <div className="p-3 text-center">
                                            <FormattedMessage id="STE_MESSAGE_WARNING_CANNOTBEUNSHARED" />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between pb-4 text-secondary">
                                            <FontAwesomeIcon icon={faFilm} size="2x" />
                                            <FontAwesomeIcon icon={faMicrophone} size="2x" />
                                            <FontAwesomeIcon icon={faImage} size="2x" />
                                            <FontAwesomeIcon icon={faFile} size="2x" />
                                        </div>
                                    </div>
                                    }
                                </Dropzone>
                            </div>
                            <Recaptcha ref={this.recaptchaRef} sitekey={this.props.recaptchaApiKey} size="invisible" onChange={this.handleRecaptchaVerificationSuccess} onErrored={this.handleRecaptchaError} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onDrop = (files: File[]) => {
        this.droppedFiles = files;
        this.recaptchaRef.current.execute();
    }

    private handleRecaptchaVerificationSuccess = (response: string) => {
        this.props.createEvidences(this.droppedFiles, response);
        this.recaptchaRef.current.reset();
    };

    private handleRecaptchaError = () => {
        // The backend will reject the request. A generic error message will be displayed besides each file.
        this.props.createEvidences(this.droppedFiles, null);
        this.recaptchaRef.current.reset();
    };

    private renderLinkInitial() {
        return this.renderLinkLoading();
    }

    private renderLinkLoading() {
        return (
            <div className="flex-container pt-5">
                <FontAwesomeIcon icon="spinner" spin={true} size="2x" />
                <FormattedMessage id="STE_MESSAGE_INFO_LOADINGFILEREQUEST" />
            </div>
        );
    }

    private renderLinkError() {
        return (
            <div className="flex-container pt-5">
                <FormattedMessage id="STE_MESSAGE_ERROR_LOADINGFILEREQUEST" />
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, props: UploadViewProps) {
    return {
        ...state.link,
        ...state.clientSettings,
        ...state.evidence,
        ...props
    };
}
function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...LinkStore.actionCreators, ...EvidenceStore.actionCreators, push }, dispatch) };
}
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(UploadView)));
