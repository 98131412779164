import * as EvidenceStore from "../../stores/evidence";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { submit } from 'redux-form'
import { bindActionCreators } from "redux";
import { IApplicationState as ApplicationState } from "../../stores";
import * as LinkStore from "../../stores/link";
import * as React from "react";
import { FormattedMessage, FormattedRelative, InjectedIntlProps, injectIntl } from "react-intl";
import { faLink } from "@fortawesome/fontawesome-free-solid";
import IncidentInfo from "../IncidentInfo";
import IdentityForm, { IdentityFormData, IdentityFormProps } from "../IdentityForm";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

type PublicViewProps =
    LinkStore.ILinkState
    & EvidenceStore.IEvidenceState
    & typeof LinkStore.actionCreators
    & typeof EvidenceStore.actionCreators
    & typeof submit
    & InjectedIntlProps
    & RouteComponentProps<{linkId: string}>;

class PublicView extends React.Component<PublicViewProps, {}> {

    public componentWillMount() {
        this.props.loadLink(this.props.match.params.linkId);
    }

    public componentDidUpdate(previousProp: PublicViewProps) {
        if (this.props.tenantId !== previousProp.tenantId) {
            this.props.getUserTheme(this.props.tenantId);
        }
        if (this.props.tenantTheme !== previousProp.tenantTheme) {
            if (this.props.tenantTheme.themeId) {
                const favicon = `favicon-${this.props.tenantTheme.themeId}.ico`;
                const link = document.createElement("link");
                link.href=`${favicon}`;
                link.rel = "shortcut icon"
                document.head.appendChild(link);
            }
        }
    }

    public render() {

        if (this.props.isLinkLoaded === false && this.props.isLinkError === false)  {
            return this.renderLinkInitial();
        } else if (this.props.isLinkLoading === true) {
            return this.renderLinkLoading();
        } else if (this.props.isLinkLoading === false && this.props.isLinkLoaded === false && this.props.isLinkError === true ) {
            return this.renderLinkError();
        } else {
            return this.renderLink();
        }
    }
    private save = (values: IdentityFormData, dispatch: Dispatch<{}>, props: IdentityFormProps) => {
        if (this.props.isTermAccepted) {
            this.props.updateUserInfo(values as any); // Force a conversion.
            this.props.history.push(`/${this.props.linkId}/upload`);
        } else {
            alert(this.props.intl.formatMessage({ id: "STE_MESSAGE_ERROR_ACCEPTTERMS" }));
        }
    }

    private renderLink() {
        return (
            <div className="flex-container h-100">
                <div>
                    <h4 className="text-white p-1 pt-5 text-center"><FormattedMessage id="STE_TITLE_PUBLICPAGE" values={{"tenantName": this.props.tenantName}}/></h4>
                    <span className="text-white text-center d-block pt-0 pb-4">
                        { this.props.linkExpirationUtc != null ? <FormattedMessage id="STE_LABEL_EXPIRY" values={{"expiry": <FormattedRelative value={this.props.linkExpirationUtc}/>}}/> : ""}
                    </span>
                </div>
                <IncidentInfo CollapseMap={false} CollapseQR={true}/>
                <IdentityForm onSubmit={this.save} uploadPrivacy={this.props.uploadPrivacy} />
                <div className={`${!LinkStore.hasTermsAndConditions(this.props.termsAndConditions) ? "d-none" : "d-flex"} justify-content-center w-100 text-white pt-5`}>
                    <div className="p-4 box bg-dark col-12 col-sm-10 col-md-9 col-lg-7 col-xl-7 d-flex flex-column justify-content-start">
                        <div>
                            <h4 className="text-white p-3"><FormattedMessage id="STE_TITLE_TERMS"/></h4>
                        </div>
                        <div className="p-3 fw-light pb-4 pre-line">
                            {this.props.termsAndConditions}
                        </div>
                        <div className="p-3">
                            <input type="checkbox" id="chkTermsAndConditions" onClick={this.toggleAcceptTerm} checked={this.props.isTermAccepted}/>
                            <label htmlFor="chkTermsAndConditions" className="ps-2 pe-2"><FormattedMessage id="STE_CHECKBOX_TERMS" /></label>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center w-100 text-white pt-3">
                    <div className="p-3 col-12 col-sm-10 col-md-9 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
                        <div className="flex-row d-flex justify-content-center">
                            <button className="btn btn-primary" onClick={this.handleShareFiles}><FormattedMessage id="STE_BUTTON_SHAREFILES"/></button>
                        </div>
                    </div>
                </div>
            </div>
           );
    }

    private toggleAcceptTerm = (e) => {
        this.props.acceptTerm(!this.props.isTermAccepted);
    }

    private handleShareFiles = () => {
        (this.props as any).submit('identity');
    }

    private onDrop = (files: File[]) => {
        files.map((file: File) => this.props.createEvidence(file));
    }

    private renderLinkInitial() {
        return this.renderLinkLoading();
    }

    private renderLinkLoading() {
        return (
            <div className="flex-container pt-5">
                <div className="splash-inner">
                    <div className="splash-inner-align splash-inner-align-top"/>
                    <div><h4 className="splash-text text-white text-center"><FormattedMessage id="STE_MESSAGE_INFO_LOADINGFILEREQUEST"/> <span>.</span><span>.</span><span>.</span></h4></div>
                    <div className="splash-inner-align splash-inner-align-bottom"/>
                </div>
            </div>
        );
    }

    private renderLinkError() {
        let errorMessage;
        if (!this.props.linkError._data) {
            errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_UNEXPECTED" />;
        } else if (this.props.linkError._data.code === "LinkNotFound") {
            errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_LINKNOTFOUND" />;
        } else if (this.props.linkError._data.code === "LinkExpired") {
            errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_LINKEXPIRED" />;
        } else {
            errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_UNEXPECTED" />;
        }
        return (
            <div className="flex-container pt-5">
                <h4 className="text-white p-1 pt-5 pb-5 text-center">
                    <FormattedMessage id="STE_MESSAGE_ERROR_LOADINGFILEREQUEST" />
                </h4>
                <h1 className="p-5 text-center"><FontAwesomeIcon icon={faLink} size="5x" /></h1>
                <h5 className="text-white p-1 pt-2 text-center">
                    {errorMessage}
                </h5>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, props: PublicViewProps) {
    return {
        ...state.link,
        ...state.evidence,
        ...props
    };
}
function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...LinkStore.actionCreators, ...EvidenceStore.actionCreators, submit}, dispatch) };
}
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PublicView)));
