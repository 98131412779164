import * as React from "react";
import { Dispatch } from "redux";
import { Field, FormErrors, FormProps, reduxForm } from 'redux-form';
import { FormattedMessage } from "react-intl";
import { UploadPrivacyType } from "../stores/link";
import * as classNames from 'classnames';

export interface IdentityFormData {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    phoneNumber?: string;
}

export interface IdentityFormProps extends FormProps<IdentityFormData, {}, {}> {
    dispatch?: Dispatch<{}>;
    uploadPrivacy?: UploadPrivacyType;

    // tslint:disable-next-line:max-line-length
    onSubmit: (values: IdentityFormData, dispatch: Dispatch<{}>, props: IdentityFormProps) => void | FormErrors<FormData> | Promise<any>;
}

const renderInput = (field: any, className: string) => {
    const hasError = !!field.meta.error && !!field.meta.touched;

    const classNameArray = ['form-group', { 'has-error': hasError }];
    const classNamesResult = classNames(classNameArray);

    return (
        <div className={classNamesResult}>
            <label className="col-sm-10 control-label text-secondary fw-bold" htmlFor={field.label}>{field.label}</label>
            <div className="col-sm-10">
                <input {...field.input} type={field.type} className={`form-control ${hasError ? "is-invalid": ""}`} />
                {hasError && <span className="invalid-feedback">{field.meta.error}</span>}
            </div>
        </div>
    );
};

class IdentityForm extends React.Component<IdentityFormProps, {}> {

    public render() {
        const isAnonymous = this.props.uploadPrivacy === UploadPrivacyType.Anonymous;
        return (
            <div className="d-flex justify-content-center w-100 text-white pt-5">
                <div className="p-4 box bg-dark col-12 col-sm-10 col-md-9 col-lg-7 col-xl-7 d-flex flex-column justify-content-start">
                    <div className="">
                        <h4 className="text-white ps-3 pb-3">
                            {isAnonymous ? <FormattedMessage id="STE_TITLE_IDENTITY_OPTIONAL"/> : <FormattedMessage id="STE_TITLE_IDENTITY"/>}
                        </h4>
                    </div>
                    <form className="" onSubmit={(this.props as any).handleSubmit!(this.props.onSubmit)}>
                        <div className="row">
                            <div className="col-12 col-md-6 mt-2">
                                <Field name="firstName"
                                    autocomplete="fname"
                                    component={renderInput}
                                    label={<FormattedMessage id="STE_LABEL_IDENTITY_FIRSTNAME"/>}
                                    type="text" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <Field name="lastName"
                                    autocomplete="lname"
                                    component={renderInput}
                                    label={<FormattedMessage id="STE_LABEL_IDENTITY_LASTNAME"/>}
                                    type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 mt-2">
                                <Field name="emailAddress"
                                    autocomplete="email"
                                    component={renderInput}
                                    label={<FormattedMessage id="STE_LABEL_IDENTITY_EMAIL"/>}
                                    type="text" />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                                <Field name="phoneNumber"
                                    autocomplete="tel"
                                    component={renderInput}
                                    label={isAnonymous ? <FormattedMessage id="STE_LABEL_IDENTITY_PHONE"/> : <FormattedMessage id="STE_LABEL_IDENTITY_PHONE_OPTIONAL"/>}
                                    type="text" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
const validate = (values: IdentityFormData, props: IdentityFormProps): FormErrors<IdentityFormData> => {
    const { firstName, lastName, emailAddress } = values;
    const errors: FormErrors<IdentityFormData> = {};

    if(props.uploadPrivacy === UploadPrivacyType.Anonymous) {
        return;
    }

    if (!firstName) {
        errors.firstName = <FormattedMessage id="STE_MESSAGE_ERROR_VALIDATION_REQUIREDFIELD" />;
    }

    if (!lastName) {
        errors.lastName = <FormattedMessage id="STE_MESSAGE_ERROR_VALIDATION_REQUIREDFIELD" />;
    }
    
    if (!emailAddress) {
        errors.emailAddress = <FormattedMessage id="STE_MESSAGE_ERROR_VALIDATION_REQUIREDFIELD" />;
    } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)){
        errors.emailAddress = <FormattedMessage id="STE_MESSAGE_ERROR_VALIDATION_INVALIDEMAIL" />;
    }
    
    return errors;
};
export default reduxForm({
    form: 'identity',  // a unique identifier for this form,
    validate
})(IdentityForm);
