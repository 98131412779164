import * as LinkStore from "../stores/link";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IApplicationState as ApplicationState } from "../stores";
import { FormattedMessage, FormattedRelative, InjectedIntlProps, injectIntl } from "react-intl";
import * as EvidenceStore from "../stores/evidence";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faCheck, faFile, faTimes } from "@fortawesome/fontawesome-free-solid";
import { IUploadCompletedMessage, IUploadProgressMessage } from "../worker/uploadWorker/uploadWorker";
import { EvidenceErrorCodes, PublicEvidenceDescriptionState } from "../stores/evidence";
// tslint:disable-next-line:no-var-requires
const UploadWorker = require("workerize-loader!../worker/uploadWorker/uploadWorker");

type EvidenceUploadProps =
    LinkStore.ILinkState
    & EvidenceStore.IEvidenceState
    & typeof LinkStore.actionCreators
    & typeof EvidenceStore.actionCreators
    & InjectedIntlProps
    & { publicEvidenceId: number };

class EvidenceUpload extends React.Component<EvidenceUploadProps, { comments: string }> {
    private _node: HTMLInputElement = null;
    private _uploadWorkerInstance = null;
    private _isUploadStarted = false;

    constructor(props) {
        super(props);
        this.state = { comments: "" };
    }
    public componentDidUpdate() {
        const evidence: EvidenceStore.IPublicEvidence = this.props.evidences.find !== undefined && this.props.evidences.find((_evidence: EvidenceStore.IPublicEvidence) => _evidence.publicEvidenceId === this.props.publicEvidenceId);
        if (!this._isUploadStarted && evidence !== undefined && evidence.remoteFile != null && evidence.remoteFile.uri !== undefined) {
            this._isUploadStarted = true;
            this._uploadWorkerInstance.Upload(evidence.localFile, evidence.remoteFile.uri, evidence.localFile.type, this.props.linkId, this.props.publicEvidenceId);
        }
    }

    public componentWillMount() {
        this._uploadWorkerInstance = new UploadWorker();
        this._uploadWorkerInstance.onmessage = (e: MessageEvent) => {
            switch (e.data.type) {
                case "IUploadCompletedMessage":
                    const completedMsg: IUploadCompletedMessage = e.data as IUploadCompletedMessage;
                    console.log("Upload completed", e.data);
                    this.props.uploadCompleteEvidence(this.props.publicEvidenceId, completedMsg.isSuccess, completedMsg.error, e.data.checksum);
                    break;
                case "IUploadProgressMessage":
                    const progressMsg: IUploadProgressMessage = e.data as IUploadProgressMessage;
                    console.log("Upload progress", e.data);
                    this.props.uploadProgressEvidence(this.props.publicEvidenceId, progressMsg.progress, progressMsg.speed);
                    break;
                case "RPC":
                    break; // do nothing.
                default:
                    console.log("Unexpected message", e);
                    break;
            }
        }
    }
    public renderStatus = () => {
        const currentEvidence: EvidenceStore.IPublicEvidence = this.props.evidences.find((evidence: EvidenceStore.IPublicEvidence) => evidence.publicEvidenceId === this.props.publicEvidenceId);
        const expectedCompletionTime: Date = new Date();
        expectedCompletionTime.setSeconds(expectedCompletionTime.getSeconds() + currentEvidence.uploadRemainingDurationSeconds);
        switch (currentEvidence.state) {
            case EvidenceStore.PublicEvidenceState.Starting:
                return <FormattedMessage id="STE_MESSAGE_INFO_UPLOAD_STARTING" />;
            case EvidenceStore.PublicEvidenceState.Uploading:
                return currentEvidence.uploadRemainingDurationSeconds > 0 ? <FormattedRelative value={expectedCompletionTime} updateInterval={-1} /> : <FormattedMessage id="STE_MESSAGE_INFO_UPLOAD_CALCULATING" />;
            case EvidenceStore.PublicEvidenceState.Completing:
                return <FormattedMessage id="STE_MESSAGE_INFO_UPLOAD_COMPLETING" />;
            default:
                return <span>&nbsp;</span>;
        }

    }

    public getErrorMessage(evidence: EvidenceStore.IPublicEvidence): JSX.Element {
        let errorMessage: JSX.Element;
        switch (evidence.uploadError) {
            case EvidenceErrorCodes.caseClosed:
                errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_INCIDENTCLOSED" />;
                break;
            case EvidenceErrorCodes.maxFileCountReached:
                errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_MAXFILECOUNTREACHED" />;
                break;
            default:
                errorMessage = <FormattedMessage id="STE_MESSAGE_ERROR_FILEUPLOADFAILED" />;
        }

        const contactInfo = [
            this.props.contactInfo && this.props.contactInfo.emailAddress,
            this.props.contactInfo && this.props.contactInfo.phoneNumber,
        ].filter((x: string) => x != null && x.length > 0);

        if (contactInfo.length > 0) {
            const orLabel = this.props.intl.formatMessage({ id: "STE_LABEL_DROPZONE_OR" });
            const contactInfoMessage = <FormattedMessage id="STE_MESSAGE_INFO_CONTACTFORASSISTANCE" values={{ "contactInfo": contactInfo.join(` ${orLabel} `) }} />;
            return <span>{errorMessage} {contactInfoMessage}</span>;
        }

        return errorMessage;
    }

    public render() {
        const currentEvidence: EvidenceStore.IPublicEvidence = this.props.evidences.find((evidence: EvidenceStore.IPublicEvidence) => evidence.publicEvidenceId === this.props.publicEvidenceId);
        const percent = Math.floor(Math.min(currentEvidence.uploadProgress * 100, 100));
        return (
            <div className="text-white p-3 mb-4 bg-dark box">
                <div className="row">
                    <div className="d-flex align-items-center ps-3 pb-2 me-auto text-truncate col-md-7 col-12">
                        <FontAwesomeIcon icon={faFile} size="lg" />
                        <span className="ps-2">{currentEvidence.localFile.name}</span>
                    </div>
                    <div className={`col-12 col-md-5 pe-3 text-end pb-1 ${currentEvidence.state === EvidenceStore.PublicEvidenceState.Error ? "d-block text-danger" : "d-none"}`}>
                        <FontAwesomeIcon className="text-danger pe-1" icon={faTimes} /> {this.getErrorMessage(currentEvidence)}
                    </div>
                    <div className={`col-12 col-md-5 pe-3 text-end pb-1 ${currentEvidence.state === EvidenceStore.PublicEvidenceState.Completed ? "d-block" : "d-none"}`}>
                        <FontAwesomeIcon className="text-success pe-1" icon={faCheck} /> <FormattedMessage id="STE_MESSAGE_INFO_FILEUPLOADSUCCESS" />
                    </div>
                    <div className={`col-12 col-md-5 pe-3 text-end pb-1 ${currentEvidence.state === EvidenceStore.PublicEvidenceState.Uploading ? "" : "d-none"}`}>
                        <button className="btn btn-secondary" onClick={this.handleCancelClick}>
                            <FormattedMessage id="STE_BUTTON_CANCEL" />
                        </button>
                    </div>
                </div>
                <div className={`progress ${currentEvidence.state === EvidenceStore.PublicEvidenceState.Uploading || currentEvidence.state === EvidenceStore.PublicEvidenceState.Starting ? "" : "d-none"}`}>
                    <div className={`progress-bar`} role="progressbar" aria-valuenow={currentEvidence.uploadProgress * 100} aria-valuemin={0} aria-valuemax={100} style={{ width: `${percent}%` }}>
                        {percent > 4 ? `${percent} %` : ""}
                    </div>
                </div>
                {this.renderStatus()}
                <div className="">
                    <div className="d-flex flex-column align-items-center ps-0 pb-2 no-gutters">
                        <div className="col-12">
                            <div className={`form-group no-gutters`}>
                                <label className="col-sm-10 control-label text-secondary no-marging"><FormattedMessage id="STE_LABEL_COMMENTS" /></label>
                                <div className="col-12">
                                    <input ref={(node) => this._node = node} onChange={this.handleCommentChanged} maxLength={250} readOnly={currentEvidence.descriptionState !== PublicEvidenceDescriptionState.NotUpdated || currentEvidence.state === EvidenceStore.PublicEvidenceState.Error} type="text" className={`form-control no-padding`} />
                                </div>
                                <div className="col-12 text-end text-secondary">
                                    <small>{this.state.comments ? this.state.comments.length : "0"}/{this._node ? this._node.maxLength : ""}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <button className={`btn btn-primary ${(currentEvidence.state === EvidenceStore.PublicEvidenceState.Starting || (this.state.comments.length === 0)) || currentEvidence.state === EvidenceStore.PublicEvidenceState.Error ? "d-none" : ""}`} disabled={currentEvidence.descriptionState !== PublicEvidenceDescriptionState.NotUpdated} onClick={this.handleSaveClick}>
                                {currentEvidence.descriptionState === PublicEvidenceDescriptionState.NotUpdated ? <FormattedMessage id="STE_BUTTON_SAVE" /> : ""}
                                {currentEvidence.descriptionState === PublicEvidenceDescriptionState.Updating ? <FormattedMessage id="STE_BUTTON_SAVING" /> : ""}
                                {currentEvidence.descriptionState === PublicEvidenceDescriptionState.Updated ? <FormattedMessage id="STE_BUTTON_SAVED" /> : ""}
                                {currentEvidence.descriptionState === PublicEvidenceDescriptionState.Error ? <FormattedMessage id="STE_BUTTON_SAVE" /> : ""}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public handleSaveClick = () => {
        this.props.updateEvidence(this.props.publicEvidenceId, this._node.value);
    }
    public handleCommentChanged = (e) => {
        this.setState({ comments: e.target.value })
    }

    public handleCancelClick = () => {
        this._uploadWorkerInstance.CancelUpload();
    }

}

function mapStateToProps(state: ApplicationState, props: EvidenceUploadProps) {
    return {
        ...state.link,
        ...state.evidence,
        ...props
    };
}
function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators({ ...LinkStore.actionCreators, ...EvidenceStore.actionCreators }, dispatch) };
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EvidenceUpload));
